import { LOCALSTORAGE_KEY } from "@/custom_config";

import { reactive, watch } from "vue";

const userState = reactive({
    user: JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || null,
});

const setUserStore = (newUser) => {
    console.log(newUser);
    userState.user = newUser;
    if (newUser) {
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newUser));
    }
};

const clearUserStore = () => {
    userState.user = {};
    localStorage.removeItem(LOCALSTORAGE_KEY);
    localStorage.clear();
};

// Assicurati che i cambiamenti siano reattivi
watch(
    () => userState.user,
    (newValue) => {
        console.log(newValue);
        //userState.user = newValue;
        if (newValue) {
            localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newValue));
        }
    },
    { deep: true, immediate: true }
);

export function useUserStore() {
    return {
        user: userState.user,
        setUserStore,
        clearUserStore,
    };
}
